<template>
    <modal ref="modalProductoStock" titulo="Productos comprados aún en stock" :cargado-modal="cargando" tamano="modal-xl" no-aceptar no-cancelar>
        <div class="row mx-0 justify-center">
            <div class="col-10 text-center text-general f-17 f-500 mb-3">
                {{ nombreTipo }}: {{ nombre }}
            </div>
            <div class="col-10 text-center text-general f-500 mb-3">
                {{ semana }}
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12">
                <tabla-general class-header="text-general f-16 text-center" alto="calc(100vh - 575px)" :usar-paginacion="false" :mostrar-buscador="false" :data="dataTable" :columnas="dataColumns" />
            </div>
        </div>
    </modal>
</template>

<script>
import Inventario from "~/services/inventarios_estadisticas";

export default {
    data(){
        return {
            dataTable: [],
            dataColumns: [
                {valor: 'nombre', titulo: 'Producto', class: 'f-16'},
                {valor: 'valor', titulo: 'Valor U.', class: 'f-16'},
                {valor: 'cantidad', titulo: 'Unidades', class: 'f-16'},
                {valor: 'total_comprado', titulo: 'Valor Compra', class: 'f-16'},
                {valor: 'fecha_compra', titulo: 'Fecha Compra', class: 'f-16'},
                {valor: 'comprador', titulo: 'Comprador', class: 'f-16'},
                {valor: 'cantidad_restante', titulo: 'U. Restantes', class: 'f-16'},
                {valor: 'total_inventario', titulo: 'Valor inventario', class: 'f-16'},
            ],
            semana: '',
            tipoInventario: 1,
            nombre: '',
            cargando: false
        }
    },
    computed: {
        nombreTipo(){
            switch (this.tipoInventario){
            case 1:
                return 'Cedis'
            case 2:
                return 'Leecheros'
            case 3:
                return 'Equipo de leecheros'
            default:
                return 'Cedis'
            }
        }
    },
    methods: {
        toggle({params , obj = {}, tipoInventario = 1, nombre = ''}){
            this.semana = obj.semana
            this.tipoInventario = tipoInventario
            this.nombre = nombre
            this.verStockSemana(params)
            this.$refs.modalProductoStock.toggle();
        },
        async verStockSemana(params){
            try {
                this.cargando = true

                const { data } = await Inventario.verStockSemana(params)
                data.productos.map(el => {
                    el.valor = this.separadorNumero(el.valor)
                    el.cantidad = this.agregarSeparadoresNumero(el.cantidad)
                    el.total_comprado = this.separadorNumero(el.total_comprado)
                    el.cantidad_restante = this.agregarSeparadoresNumero(el.cantidad_restante)
                    el.total_inventario = this.separadorNumero(el.total_inventario)
                    el.fecha_compra = this.formatearFecha(el.fecha)
                })
                this.dataTable = data.productos


            } catch (error){
                console.log(error);
            }finally{
                this.cargando = false
            }

        },
    }
}
</script>
